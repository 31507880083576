import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';

const optimizely = createInstance({
    sdkKey: "6pEtc2TyPAHN3GesdNYkP",
    datafileOptions: {
      autoUpdate: true,
      updateInterval: 15000, 
    }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <OptimizelyProvider
    optimizely={optimizely}
    user={{ id: crypto.randomUUID() }}>
        <App />
    </OptimizelyProvider>
);
