import * as yup from 'yup';

export const phoneRegex = /^(\+?1-?)?(\([0-9]\d{2}\)|[0-9]\d{2})[-. ]?[0-9]\d{2}[-. ]?\d{4}$/
export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

export const submitFormValidationSchema = yup.object().shape({
    name: yup.string().trim().required('Please enter your full name').min(2, "Please enter valid Name").max(64, "Please enter valid Name"),
    email: yup
        .string()
        .trim()
        .email('Enter valid e-mail')
        .matches(emailRegex, 'Enter valid e-mail')
        .required('Email is required'),
    phone: yup.string().trim().matches(phoneRegex, 'Please enter valid phone number').required('Phone number is required'),
});

export const submitFormValidationSchemaLP2 = yup.object().shape({
    name: yup.string().trim().required('Please enter your full name').min(2, "Please enter valid Name").max(64, "Please enter valid Name"),
    email: yup
        .string()
        .trim()
        .email('Enter valid e-mail')
        .matches(emailRegex, 'Enter valid e-mail')
        .required('Email is required'),
    phone: yup.string().trim().matches(phoneRegex, 'Please enter valid phone number').required('Phone number is required'),
    income: yup.object({
        label: yup.string().required(),
        value: yup.string().required(),
    }).required('Field is required'),
    agent: yup.bool().optional()
});




export default submitFormValidationSchema