import {  withOptimizely } from "@optimizely/react-sdk";
import { SubmitFormButtonStyled } from "../SubmitForm/SubmitForm.styles";

const SubmitButton = (props) => {
    const { optimizely, onClick, disabled, isValid } = props

    const handleClick = () => {
        if (!disabled) {
            optimizely.track('submit')
        }
        if (onClick) {
            onClick()
        }
    }

    return (
        <SubmitFormButtonStyled type="submit" disabled={disabled || !isValid} tabIndex={0} onClick={handleClick}>
            {props.children}
        </SubmitFormButtonStyled>
    )
}

export default withOptimizely(SubmitButton)