import './App.css';
import AboutBlock from './Components/AboutBlock';
import Footer from './Components/Footer/Footer';
import FormContainer from './Components/FormContainer';
import MainLayout from './Components/MainLayout/MainLayout';
import OurProcessBlock from './Components/OurProcessBlock/OurProcessBlock';

function App() {
  return (
    <div className="App">
      <MainLayout>
        <FormContainer/>
        <AboutBlock/>
        <OurProcessBlock/>
        <Footer/>
      </MainLayout>
    </div>
  );
}

export default App;
