import styled from "styled-components";

export const MainLayoutStyled = styled('div')`
width: 100%;

.content-container {
    padding-top: 90px;
    

    @media screen and (max-width: 780px) {
        padding-top: 74px;
    }
}
`

export default MainLayoutStyled;