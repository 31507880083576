import styled from "styled-components";
import background from "../../Assets/pencil.png"

const FormContainerStyles = styled('div')`
display: flex;
min-height: 720px;
padding-top: 72px;
padding-left: 288px;
padding-right: 160px;
background: bottom 0px right 0px / auto no-repeat url('${background}'), #211E32;
justify-content: center;

@media screen and (max-width: 1780px) {
    padding-left: 90px;
    padding-right: 40px;
}
@media screen and (max-width: 1380px) {
    padding-left: 20px;
    padding-right: 10px;
}

@media screen and (max-width: 1220px) {
    flex-direction: column;
    padding: 64px 0;
    min-height: unset;
    background: #211E32;
}

.form--wrapper {
    width: 100%;
    justify-content: space-between;

    @media screen and (max-width: 1220px) {
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        gap: 32px;
    }
    
    .form-banner--container {
        display: flex;
        flex-direction: column;
        gap: 66px;
        text-align: left;
        max-width: 866px;

        @media screen and (max-width: 1220px) {
            text-align: center;
        }

        @media screen and (max-width: 880px) {
            .typography-title--primary {
                font-size: 20px;
            }
        }

        & .form-banner--list {
            & ul {
                text-align: left;
                list-style-type: none;
                margin: 0;
                padding: 0;

                & li {
                    & svg {
                        width: 21px;
                        height: 21px;
                        margin-right: 14px;
                    }

                    padding-bottom: 26px;

                    &:last-child {
                        padding-bottom: 0;
                    }
                }
            }

            @media screen and (max-width: 1220px) {
                display: none;
            }
        }
    }
        


}

.form-banner--list-moblie {
    display: none;
    gap: 16px;
    padding-top: 32px;

    @media screen and (max-width: 1220px) {
        display: flex;
    }

    @media screen and (max-width: 880px) {
        .typography-list--item {
          font-size: 15px;
          font-weight: 400;
        }
    }
}
`
export default FormContainerStyles;