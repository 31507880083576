import React from "react";
import FormContainerStyles from "./FormContainer.styles";
import { Grid } from "@mui/material";
import SubmitForm from "../SubmitForm/SubmitForm";
import { ReactComponent as IconPerson } from "../../Assets/icon-person.svg";
import { ReactComponent as IconGraph } from "../../Assets/icon-graph-asc.svg";
import { ReactComponent as IconGlobe } from "../../Assets/icon-globe.svg";
import { ReactComponent as IconTemple } from "../../Assets/icon-temple.svg";
import { ReactComponent as IconPersonBig } from "../../Assets/icon-person-big.svg";
import { ReactComponent as IconGraphBig } from "../../Assets/icon-graph-asc-big.svg";
import { ReactComponent as IconGlobeBig } from "../../Assets/icon-globe-big.svg";
import { ReactComponent as IconTempleBig } from "../../Assets/icon-temple-big.svg";

const FormContainer = () => (
    <FormContainerStyles>
        <Grid container className="form--wrapper">
            <SubmitForm/>
            
            <Grid item className="form-banner--container">
                <Grid item>
                    <h1 className="typography typography-title--primary typography-color--gray">Your goals, our guidance:</h1>
                    <p className="typography typography-logo typography-color--primary typography-color--primary">
                        Strategic <span>Life<br/>Insurance</span> Planning
                    </p>
                </Grid>
                
                <Grid item className="form-banner--list">
                    <ul>
                        <li className="typography typography-list--item typography-color--gray">
                            <IconPerson/> Review your current policy with our wealth legacy architects
                        </li>
                        <li className="typography typography-list--item typography-color--gray">
                            <IconGraph/> Optimize your policy with human powered AI
                        </li>
                        <li className="typography typography-list--item typography-color--gray">
                            <IconGlobe/> Unparalleled independent policy recommendation
                        </li>
                        <li className="typography typography-list--item typography-color--gray">
                            <IconTemple/> Feel empowered with knowledge and lifelong aftercare
                        </li>
                    </ul>
                </Grid>
            </Grid>
            
                
            
        </Grid>
        <Grid container direction="column" className="form-banner--list-moblie">
                
                    <Grid container direction="column">
                            <Grid container justifyContent="center">
                                <IconPersonBig/>
                            </Grid>
                            <div>
                                <p className="typography typography-list--item typography-color--gray">Review your current policy with our<br/>wealth legacy architects</p>
                            </div>
                    </Grid>
                    <Grid container direction="column">
                            <Grid container justifyContent="center">
                                <IconGraphBig/>
                            </Grid>
                            <div>
                                <p className="typography typography-list--item typography-color--gray">Optimize your policy with<br/>human powered AI</p>
                            </div>
                    </Grid>
                    <Grid container direction="column">
                            <Grid container justifyContent="center">
                                <IconGlobeBig/>
                            </Grid>
                            <div>
                                <p className="typography typography-list--item typography-color--gray">Unparalleled independent policy<br/>recommendation</p>
                            </div>
                    </Grid>
                    <Grid container direction="column">
                            <Grid container justifyContent="center">
                                <IconTempleBig/>
                            </Grid>
                            <div>
                                <p className="typography typography-list--item typography-color--gray">Feel empowered with knowledge<br/>and lifelong aftercare</p>
                            </div>
                    </Grid>
        </Grid>
    </FormContainerStyles>
)

export default FormContainer;