import React, { useEffect, useState } from "react";
import SubmitFormStyled, { SubmitFormInnerStyled } from "./SubmitForm.styles";
import FormComponent from "../FormComponent";
import submitFormValidationSchema from "./validationSchemas";
import Input from "../Input/Input";
import axios from "axios";
import { Popover } from "@mui/material";
import useWindowSize from "../../hooks/useWindowSize";
import { ReactComponent as IconCross } from "../../Assets/icon-cross.svg";
import { ReactComponent as IconCheck } from "../../Assets/icon-check.svg";
import Checkbox from "../Checkbox/Checkbox";
import Select from "../Select/Select";

const InnerForm = ({ incomeSelect = false, errors, touched }) => (
    <SubmitFormInnerStyled>
        <Input
            name="name"
            label="Full Name *"
            placeholder=""
            touched={touched}
            errors={errors}
        />
        <Input
            name="email"
            label="Email *"
            placeholder=""
            touched={touched}
            errors={errors}
        /> 
        <Input
            name="phone"
            label={incomeSelect ? "Phone *" : "Phone"}
            placeholder=""
            touched={touched}
            errors={errors}
        />

        {incomeSelect && <Select
            name="income"
            label="Annual Income *"
            placeholder="Select"
            touched={touched}
            errors={errors}
            options={[
                { value: "Below $100K", label: "Below $100K"},
                { value: "$101k - $200k", label: "$101k - $200k"},
                { value: "$201k - $300k", label: "$201k - $300k"},
                { value: "$301k - $500k", label: "$301k - $500k"},
                { value: "$500k - $1MM", label: "$500k - $1MM"},
                { value: "$1M+", label: "$1M+"},
            ]}
        />}

        
        {incomeSelect && <Checkbox
            name="agent"
            label="I'm an agent / financial advisor"
            errors={errors}
            touched={touched}
        />}            
    </SubmitFormInnerStyled>
)

const SubmitForm = () => {
    const [ open, setOpen ] = useState(false)
    const [ disabled, setDisabled ] = useState(false)
    const [ queryParams, setQueryParams ] = useState({})
    const [ errorMessage, setErrorMessage ] = useState("")
    const screen = useWindowSize()
    const validationSchema = submitFormValidationSchema

    useEffect(() => {
        let tmp = {}
        if (window.location.search) {
            Object.assign(
                tmp,
                ...window.location.search
                    .replace("?", "")
                    .split("&")
                    .map((param) => param.includes("=") ? ({ [param.split("=")[0]]: param.split("=")[1]}) : {})
            )

            setQueryParams(tmp)
        }
    }, [])

    const onSubmit = (values, url) => {
        setErrorMessage("")
        const name = values.name.split(" ")
        const first_name  = name[0]      
        const last_name  = name.length > 1 ? name[1] : ""
        let payload = {
            "email": values.email.trim(),
            "firstname": first_name.trim(),
            "lastname": last_name.trim(),
            "phone": values.phone.trim().replace(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, "($1) $2-$3"),
            "utm_source": queryParams["utm_source"],
            "utm_medium": queryParams["utm_medium"],
            "utm_campaign": queryParams["utm_campaign"],
            "company": "",
            "client": "optifino"
        }
        axios.post("https://gw.atidot.co/v1/users/contacts/hubspot/create", payload, {
            headers: {
                "Access-Key": process.env.REACT_APP_ACCESS_KEY,
                "Content-Type": "application/json"
            }
        })
            .then(() => {
                setOpen(true)
                setDisabled(true)
                setTimeout(() => {
                    setDisabled(false)
                    window.location.assign(url);
                }, 3000)
            })
            .catch((error) => {
                setDisabled(false)
                if (error.response.status === 409) {
                    setErrorMessage(`Meeting with ${values.email.trim()} is already scheduled!`)
                } else if (error.response?.data?.error) {
                    setErrorMessage(error.response.data.error)
                } else {
                    setErrorMessage("Error")
                }
            })
    }

    return (
    <SubmitFormStyled>
        <Popover
            open={open}
            onClose={() => setOpen(false)}
            anchorReference="anchorPosition"
            anchorPosition={{ top: screen.height/2, left: screen.width/2 }}
            anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
            className="success--popup"
        >
            <div className="success-popup--content">
                <div className="success-popup--cross"><IconCross onClick={() => setOpen(false)}/></div>
                <div className="success-popup--icon"><IconCheck/></div>
                <div>
                    {screen.width> 992 ? 
                    <p className="typography typography-popup-title">Your response has been sent successfully.</p> 
                    : <p className="typography typography-popup-title">Your response has been<br/>sent successfully.</p> 
                    }
                    <p className="typography typography-popup-text">Optifino will reach out to you soon. You are now being redirected to optifino.com. </p>
                </div>
            </div>
            
        </Popover>
        <div className="submit-form--container">
            <p className="typography typography-title--primary typography-color--white">Explore a<br/>bespoke review!</p>


            
            <FormComponent
                className="submit-form"
                submitText="Get started"
                handleSubmit={(values, actions) => onSubmit(values, "https://meetings.hubspot.com/david-kleinhandler/bespoke_review?uuid=2d863b53-12ff-4b9d-bda0-eb7c3e6179b4")}
                validaton={validationSchema}
                disabled={disabled}
                initialValues={{
                    name: "",
                    email: ""
                }}
            >
                {({ errors, touched }) => (
                    <>
                        <InnerForm errors={errors} touched={touched}/>
                        {errorMessage &&
                            <div className="error--container">{errorMessage}</div>
                        }
                    </>
                )}
            </FormComponent>

        </div>
    </SubmitFormStyled>
)

}

export default SubmitForm;
