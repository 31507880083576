import styled from "styled-components";
import circleShadow from "../../Assets/circle-shadow.svg"

const AboutBlockStyles = styled('div')`
display: flex;
background: linear-gradient(90deg, #2D293D 0%, #110E1B 100%);
justify-content: center;
align-items: center;
height: 428px;
width: 100%;

@media screen and (max-width: 1220px) {
    height: unset;
    padding: 64px 0;
}
 
.about--container {
    display: flex;
    justify-content: center;
    gap: 100px;
    
    @media screen and (max-width: 1220px) {
        flex-direction: column;
    }

    @media screen and (max-width: 1351px) {
        gap: 30px;
    }

    .about--item {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 23px;
        
        @media screen and (max-width: 1220px) {
            padding: 0 32px;
        }

        .about--icon {
            background: #38324D4D;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 119px;
            height: 119px;
            border-radius: 50%;
            background-image: url('${circleShadow}');
            background-size: cover;
        }

        h2 {
            padding-bottom: 12px;
        }
    }
}
`
export default AboutBlockStyles;