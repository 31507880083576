import ReactSelect from 'react-select';
import { ErrorMessage, Field } from 'formik';
import { CSSTransition } from 'react-transition-group';
import SelectStyles from './Select.styles';

const Select = ({
  className = '',
  label = "",
  name,
  placeholder = "",
  type,
  disabled = false,
  errors,
  touched,
  options = [],
  instanceId = '',
  showErrorMessage,
  onChange = () => {},
}) => (
  <SelectStyles className={className}>
    <div className="input-field--wrapper">
      {label && (
        <label htmlFor={name} className="input-label">
          {label}
        </label>
      )}
      <div className="input--wrapper">
        <Field
          type={type}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          className={`input ${errors[name] && touched[name] ? 'state-danger' : ''}`}
        >
          {({ field, form }) => (
            <ReactSelect
              instanceId={instanceId}
              classNamePrefix="select"
              options={options}
              name={field.name}
              value={field.value}
              placeholder={placeholder}
              className={`input ${errors[name] && touched[name] ? 'state-danger' : ''}`}
              onChange={(option) => {
                form.setFieldValue(field.name, option, true);

                if (onChange) {
                  onChange(option);
                }
              }}
            />
          )}
        </Field>
      </div>
      {showErrorMessage && (
        <CSSTransition
          in={errors[name] && touched[name]}
          timeout={500}
          classNames="error-block-animation"
        >
          <div className="input-field--error-block-wrapper">
            <ErrorMessage
              name={name}
              render={(msg) => (
                <div className="input-error-block">
                  <i className="input-validation-icon icon-warning" />
                  <div className="input-validation-message">{msg}</div>
                </div>
              )}
            />
          </div>
        </CSSTransition>
      )}
    </div>
  </SelectStyles>
);

export default Select;
