import styled from "styled-components";

export const SubmitFormStyled = styled('div')`
display: flex;
width: 354px; 
padding: 36px 26px;
gap: 32px;
border-radius: 16px;
box-shadow: 0px 4px 4px 0px #F7F7F70D;
background: #120F1C;
height: fit-content;

@media screen and (max-width: 880px) {
    width: 268px;
    padding: 24px 26px;
}

.submit-form--container {
    width: 100%;
    text-align: left;
    
    .submit-form {
        width: 100%;

        form {
            width: 100%;
        }
    }
}
`


export const SubmitFormInnerStyled = styled('div')`
display: flex;
flex-direction: column;
text-align: left;
gap: 16px;
padding: 24px 0;

@media screen and (max-width: 880px) {
    gap: 12px;
}
`
export const SubmitFormButtonStyled = styled('button')`
display: flex;
align-items: center;
justify-content: center;
background: #482B82;
color: #F7F7F7;
width: 100%;
max-width: 162px;
height: 52px;
font-family: Manrope;
font-size: 16px;
font-weight: 400;
text-align: center;
border: none;
border-radius: 6px;
cursor: pointer;

&:disabled {
    background: #70648b;
    color: #afa9bf;
    cursor: default;

}
`

export default SubmitFormStyled;