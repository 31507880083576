import React from "react";
import AboutBlockStyles from "./AboutBlock.styles";
import { Grid } from "@mui/material";
import { ReactComponent as IconGraph } from "../../Assets/icon-graph-asc.svg";
import { ReactComponent as IconChart } from "../../Assets/icon-chart.svg";
import { ReactComponent as IconScript } from "../../Assets/icon-script.svg";

const AboutBlock = () => (
    <AboutBlockStyles>
        <Grid className="about--container">
            <Grid className="about--item">
                <div className="about--icon">
                    <IconGraph/>
                </div>

                <div>
                    <h2 className="typography typography-title--secondary typography-color--primary">Increase 2.2x your death benefit</h2>
                    <p className="typography typography-description typography-color--darkgray">
                        Increase your death benefit by as much as 220%<br/>while keeping your premiums the same
                    </p>
                </div>  
            </Grid>
            <Grid className="about--item">
                <div className="about--icon">
                    <IconChart/>
                </div>

                <div>
                    <h2 className="typography typography-title--secondary typography-color--primary">Reduce premiums up to 50%</h2>
                    <p className="typography typography-description typography-color--darkgray">
                        Decrease your premium on average by 40-50%<br/>while keeping your coverage the same
                    </p>
                </div>  
            </Grid>
            <Grid className="about--item">
                <div className="about--icon">
                    <IconScript/>
                </div>

                <div>
                    <h2 className="typography typography-title--secondary typography-color--primary">Uncover tax minimization strategies </h2>
                    <p className="typography typography-description typography-color--darkgray">
                        Leverage 100+ years of combined experience,<br/>educating you on strategic tax minimization planning 
                    </p>
                </div>  
            </Grid>
        </Grid>
    </AboutBlockStyles> 
)

export default AboutBlock;