import { Field } from 'formik';
import CheckboxStyles from './Checkbox.styles';

const Checkbox = ({ className, name, label, placeholder, disabled, errors, touched, style }) => (
    <CheckboxStyles className={className} style={style}>
      <div className="checkbox--wrapper">
        <div className="checkbox-field--wrapper">
          <Field
            type="checkbox"
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            className={`checkbox 
                                ${errors[name] && touched[name] ? 'state-danger' : ''}`}
          />
        </div>
        {label && (
          <label htmlFor={name} className="checkbox-label">
            {label}
          </label>
        )}
      </div>
    </CheckboxStyles>
);

export default Checkbox;
