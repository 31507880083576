import React from "react";
import MainLayoutStyled from "./MainLayout.styles";
import Header from "../header/Header";

const MainLayout = (props) => (
    <MainLayoutStyled>
        <Header/>
        <div className="content-container">{props.children}</div>
    </MainLayoutStyled>
)

export default MainLayout