import styled from "styled-components";

export const InputStyles = styled('div')`
width: 100%;

.input-field--wrapper {
    width: 100%;
    max-width: 332px;
    
    @media screen and (max-width: 880px) {
      width: 248px;
    }

    .input--label {
      font-family: Manrope;
      font-size: 16px;
      font-weight: 400;
      color: #F7F7F7;
    }

    .input--wrapper {
        width: 100%;
        max-width: 354px;
        padding-top: 5px;

        .input {
            width: 100%;
            max-width: 354px;
            background-color: #2D2946;
            border: 1px solid #3B3751;
            border-radius: 8px;
            height: 46px;
            font-family: Manrope;
            font-size: 16px;
            font-weight: 400;
            color: #F7F7F7;
            padding: 0 10px;
            
            &::placeholder {
            }

            &.state-danger {
              border: 1px solid red;
            }
        }
    }
}

.input-error--wrapper {
    width: 100%;

    &.error-block-animation-enter {
      opacity: 0;
    }

    &.error-block-animation-enter-active {
      opacity: 1;
      transition: opacity 500ms;
    }

    &.error-block-animation-exit {
      opacity: 1;
    }

    &.error-block-animation-exit-active {
      opacity: 0;
      transition: opacity 500ms;
    }

    .input-error--container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 5px;

      .input-error--message {
        color: red;
      }
    }
  }
`

export default InputStyles