import React from "react";
import { Formik, Form } from 'formik';
import { Grid } from "@mui/material";
import SubmitButton from "./SubmitButton";

const FormComponent = ({
  className,
  submitText,
  handleSubmit,
  initialValues,
  validaton,
  children,
  disabled,
}) => (
  <div className={className}>
    <Formik
      onSubmit={(values, actions) => {
        handleSubmit(values, actions);
      }}
      initialValues={initialValues}
      validationSchema={validaton}
    >
      {(formikProps) => {
        const { errors, isValid } = formikProps;
        return (
          <Form >
            {children(formikProps)}
            {errors.name && <div>{errors.name}</div>}
            <Grid container justifyContent="center">
              <SubmitButton disabled={disabled} isValid={isValid}>
                {submitText}
              </SubmitButton>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  </div>
);

export default FormComponent