import styled from "styled-components";
import circleShadow from "../../Assets/circle-shadow.svg"

const OurProcessBlockStyles = styled('div')`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
padding: 84px 0;
 
.process--container {
    display: flex;
    justify-content: center;
    max-width: 1186px;
    
    @media screen and (max-width: 1220px) {
        flex-direction: column;
        align-items: center;
        gap: 32px;
    }

    .process--item {
        &.process--title{   
            
            @media screen and (max-width: 1220px) {
                justify-content: center;
            }
        }

        &.process-list--container {

            .process-list--item {
                display: flex;
                padding-left: 60px;
                text-align: left;
                border-left: 4px solid #EAE7F0;
                padding-bottom: 64px;

                &:last-child {
                    border-left: 4px solid #FFF;
                    padding-bottom: 0;
                }

                & p {
                    padding-top: 12px;

                    @media screen and (max-width: 1220px) {
                        padding-top: 4px;
                    }
                }

                .number--container {
                    position: absolute;

                    .process-list--item--number {
                        background: #1C1928;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        width: 49px;
                        height: 49px;
                        border-radius: 50%;
                        font-size: 20px;
                        font-weight: 700;
                        color: #FFFFFF;
                        background-image: url('${circleShadow}');
                        background-size: cover;
                        position: relative;
                        left: -87px;
                    }
                }

            }
        }
    }
}
`
export default OurProcessBlockStyles;