import React from "react";
import FooterStyled from "./Footer.styles";
import { Grid } from "@mui/material";

const Footer = () => (
    <FooterStyled>
        <Grid className="footer--content" justifyContent="center" container>
            <Grid className="footer-links--container" justifyContent="center" container>
            <Grid className="footer-terms--content" container>
                <div className="footer-terms--item">
                    <a className="typography typography-color--primary" href="https://cdn.optifino.com/pdf/Optifino%20Privacy%20Policy.pdf">
                        Privacy Policy
                    </a>
                </div>
                <div className="footer-terms--item">
                    <a className="typography typography-color--primary" href="https://cdn.optifino.com/pdf/Optifino%20Terms%20of%20Use.pdf">
                    Terms of Use
                    </a>
                </div>
            </Grid>
            <Grid className="footer-social--content" container>
                <div>
                    <a href="https://twitter.com/OptifinoLife">
                        <img loading="lazy" src="https://optifino.com/hubfs/x%20logo.svg" alt="twitter"/>
                    </a>
                </div>
                <div>
                    <a href="https://www.linkedin.com/company/91616001">
                        <img loading="lazy" src="https://optifino.com/hubfs/linkedin.svg" alt="linkedin"/>
                    </a>
                </div>
                <div>
                    <a href="https://blog.optifino.com/">
                        <img loading="lazy" src="https://optifino.com/hubfs/substack%20logo.svg" alt="twitter"/>
                    </a>
                </div>
            </Grid>
            </Grid>
            <Grid className="footer-text--container" justifyContent="center" container>
                <p className="typography typography-copyright typography-color--primary">Life insurance technology and life insurance are provided by Optifino, Inc. (DBA Optifino Insurance Services in New York) (“Optifino”). Optifino is a licensed insurance producer in all states where it offers products, and acts as an agent for various insurance companies. License information available here. The information on this website has been developed by Optifino, Inc and is for informational purposes only. We do our best to ensure that this information is up-to-date and accurate. Any insurance policy premium quotes or ranges displayed are non-binding. The final insurance policy premium for any policy is determined by the underwriting insurance company following application. Products and specific product features may not be available in all states, and other limitations or restrictions may apply. Securities offered through The Leaders Group, Inc. Member FINRA/SIPC 26 W Dry Creek Circle, Suite 800, Littleton, CO 80120, 303-797-9080. Optifino is not affiliated with The Leaders Group, Inc. Check the background of your financial professional or broker/dealer with FINRA</p>
                <p className="typography typography-security typography-color--primary">Securities offered through The Leaders Group, Inc., Member FINRA/SIPC, 26 W Dry Creek Circle, Suite 800,<br/>Littleton, CO 80120. (303) 797-9080. Optifino is not affiliated with The Leaders Group, Inc.</p>
            </Grid>
        </Grid>
    </FooterStyled>
)

export default Footer