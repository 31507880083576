import React from "react";
import OurProcessBlockStyles from "./OurProcessBlock.styles";
import { Grid } from "@mui/material";
const OurProcessBlock = () => (
    <OurProcessBlockStyles>
        <Grid className="process--container" container>
            <Grid className="process--item process--title" alignItems="center" justifyContent="flex-start" item container xs={6}>
                <h3 className="typography typography-list--title typography-color--secondary">Our Process</h3>  
            </Grid>
            <Grid  className="process--item process-list--container" item container xs={6}>
                <Grid className="process-list--item">  
                    <div className="number--container">
                        <div className="process-list--item--number">1</div>
                    </div>

                    <div>
                        <h4 className="typography typography-title--secondary typography-color--secondary">Goals Discovery</h4>
                        <p className="typography typography-description typography-color--secondary">
                            Our Wealth Legacy Architects learn about you and your goals crafting a bespoke policy review.
                        </p>
                    </div>
                </Grid>  
                
                <Grid className="process-list--item">  
                    <div className="number--container">
                        <div className="process-list--item--number">2</div>
                    </div>

                    <div>
                        <h4 className="typography typography-title--secondary typography-color--secondary">Portfolio Design</h4>
                        <p className="typography typography-description typography-color--secondary">
                        Optifino leverages advanced machine learning algorithms for optimal policy selection by comparing hundreds of products across top carriers. We support this process with expert human guidance and deep insights into life insurance benefits.
                        </p>
                    </div>
                </Grid>  

                
                <Grid className="process-list--item">  
                    <div className="number--container">
                        <div className="process-list--item--number">3</div>
                    </div>

                    <div>
                        <h4 className="typography typography-title--secondary typography-color--secondary">Onboarding & Underwriting</h4>
                        <p className="typography typography-description typography-color--secondary">
                            Once your bespoke portfolio has been crafted, your Wealth Legacy Architect manages all the necessary steps to ensure your strategic life insurance plan.
                        </p>
                    </div>
                </Grid>  
                
                <Grid className="process-list--item">  
                    <div className="number--container">
                        <div className="process-list--item--number">4</div>
                    </div>

                    <div>
                        <h4 className="typography typography-title--secondary typography-color--secondary">Lasting Collaboration</h4>
                        <p className="typography typography-description typography-color--secondary">
                        Optifino fosters a relationship with you to ensure your policy continues to align with your goals as they evolve, including annual policy reviews, providing policy performance updates, managing trust owned life insurance, and monitoring market updates that affect carriers and policies.
                        </p>
                    </div>
                </Grid>  
            </Grid>
        </Grid>
    </OurProcessBlockStyles> 
)

export default OurProcessBlock;