import styled from "styled-components";

export const FooterStyled = styled('div')`
display: flex;
width: 100%;
padding-top: 32px;
padding-bottom: 26px;
background: #120F1C;
justify-content: center;
align-items: center;


.footer--content {
    max-width: 1320px;
    border-top: 1px solid #373936;
    
    @media screen and (max-width: 1400px) {
      max-width: 1140px;
    }
    
    @media screen and (max-width: 1200px) {
      max-width: 940px;
    }
    
    @media screen and (max-width: 992px) {
      max-width: 720px;
    }
    
    @media screen and (max-width: 768px) {
      max-width: unset;
    }
    
    .footer-links--container {
        padding: 15px 0 25px; 
        @media screen and (max-width: 992px) {
            padding: 0; 
        }
        
        .footer-terms--content {
            gap: 25px;
            flex-basis: 50%;
            max-width: 50%;

    
            @media screen and (max-width: 992px) {
              flex-basis: 100%;
              max-width: 100%;
              padding: 14px 0; 
              border-bottom: 1px solid #373936;        
              gap: unset;
              justify-content: space-evenly;
              
              a {
                margin: 0!important;
              }
            }

            .footer-terms--item {
                display: flex;
                align-items: flex-end;
    
                a {
                    display: flex;
                    text-decoration: none;
                    margin: 0 10px 10px;
    
                    &:hover {
                        color: #BCC5C4;
                    }
                }
            }
        }
        
        .footer-social--content {
            gap: 16px;
            flex-basis: 50%;
            max-width: 50%;
            justify-content: flex-end;
    
            @media screen and (max-width: 768px) {
              flex-basis: 100%;
              max-width: 100%;
              justify-content: center;
              gap: 75px;
              padding: 30px 0 25px; 
            }

            a {
                display: flex;
                padding: 10px;
                border-radius: 8px;
                border: 2px solid #482b82;
            }
        }
    }
    
    .footer-text--container {
        opacity: .7;
        gap: 32px;

        @media screen and (max-width: 768px) {
          padding: 0 15px;
        }
    }
}
`;

export default FooterStyled;