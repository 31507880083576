import styled from 'styled-components';

export const SelectStyles = styled('div')`
  width: 100%;

  .input-field--wrapper {
    width: 100%;

    .input-label {
      width: 100%;
      display: block;
      margin-bottom: 4px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: #F7F7F7;
    }

    .input--wrapper {
      width: 100%;

      .input {
        &.state-danger {
          .select__control {
            border: 1px solid 
            color: red;
          }
        }

        .select__control {
          width: 100%;
          border: 1px solid #3B3751;
          border-radius: 8px;
          box-shadow: none;
          cursor: pointer;
          background-color: #2D2946;

          &.select__control--is-focused {
            border: 1px solid #3B3751;
          }

          .select__value-container {
            padding: 12px;
            font-size: 16px;
            line-height: 24px;

            .select__placeholder {
              text-wrap: nowrap;
              color: #F7F7F7;
            }

            .select__input-container {
              padding: 0;
              margin: 0;
            }

            .select__single-value {
              margin: 0;
              color: #F7F7F7;
            }
          }
        }

        .select__indicators {
          .select__indicator-separator {
            display: none;
          }

          .select__indicator {
            padding: 0 12px 0 0;
          }
        }

        .select__menu {
          margin: 4px 0 0;
          box-shadow: 0 22px 23px -10px rgba(32, 34, 80, 0.15), 0 0 12px 0 rgba(32, 34, 80, 0.09);

          .select__menu-list {
            padding: 0;

            .select__option {
              padding: 12px;
              font-size: 16px;
              line-height: 24px;
              color: #F7F7F7;
              cursor: pointer;
              background-color: #2D2946;

              &.select__option--is-selected {
                background-color: #2D2946;
              }

              &.select__option--is-focused {
                background-color: #4F487D;
              }

              &:first-child {
                border-radius: 4px 4px 0 0;
              }

              &:last-child {
                border-radius: 0 0 4px 4px;
              }
            }
          }
        }
      }
    }

    .input-field--error-block-wrapper {
      width: 100%;
      min-height: 21px;
      margin-top: 4px;

      &.error-block-animation-enter {
        opacity: 0;
      }

      &.error-block-animation-enter-active {
        opacity: 1;
        transition: opacity 500ms;
      }

      &.error-block-animation-exit {
        opacity: 1;
      }

      &.error-block-animation-exit-active {
        opacity: 0;
        transition: opacity 500ms;
      }

      .input-error-block {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        .input-validation-icon {
          color: red;
          font-size: 14px;
        }

        .input-validation-message {
          color: #F7F7F7;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
`;

export default SelectStyles;
