import React from "react";
import HeaderStyled from "./Header.styles";
import { ReactComponent as Logo } from "../../Assets/header-logo.svg";

const Header = () => (
    <HeaderStyled>
        <Logo onClick={() => { window.location.assign("https://optifino.com/") }}/>
    </HeaderStyled>
)

export default Header