import styled from 'styled-components';
import Check from "../../Assets/icon-check.png"

export const CheckboxStyles = styled('div')`
  .checkbox--wrapper {
    display: flex;
    padding-top: 8px;
    align-items: center;

    @media screen and (max-width: 880px) {
      padding-top: 0;
    }

    .checkbox-label {
      width: 100%;
      display: block;
      padding-left: 12px;
      color: #F7F7F7;
      font-size: 16px;
      font-family: Manrope;
      line-height: 22px;
      font-weight: 400;

      @media screen and (max-width: 880px) {
        font-size: 14px;
      }

      a {
        color: #F7F7F7;
        text-decoration: underline;

        &:focus,
        &:hover {
          color: #F7F7F7;
        }
      }
    }

    .checkbox-field--wrapper {
      height: 28px;

      .checkbox {
        margin: 0;
        padding: 0;
        width: 28px;
        height: 28px;
        appearance: unset;
        border-radius: 6px;
        background: #2D2946;

        &:focus,
        &:hover {
        }

        &:checked {
          color: #DCDAE1;
          background: url('${Check}') #2D2946;
          background-size: contain;
        }

        &:disabled {
        }

        &.state-danger {
        }
      }
    }
  }

  .checkbox--error-block-wrapper {
    width: 100%;

    &.error-block-animation-enter {
      opacity: 0;
    }

    &.error-block-animation-enter-active {
      opacity: 1;
      transition: opacity 500ms;
    }

    &.error-block-animation-exit {
      opacity: 1;
    }

    &.error-block-animation-exit-active {
      opacity: 0;
      transition: opacity 500ms;
    }

    .checkbox-error-block {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 5px;

      .checkbox-validation-icon {
        margin-right: 8px;
      }

      .checkbox-validation-message {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
`;

export default CheckboxStyles;
