import styled from "styled-components";

export const HeaderStyled = styled('div')`
position: fixed;
margin: 0;
top: 0;
left: 0;
width: 100%;
height: 90px;
display: flex;
align-items: center;
padding: 0 84px;
background: #120F1C;
z-index: 1;

@media screen and (max-width: 780px) {
    height: 74px;
    padding: 0 20px;
}

svg {
    cursor: pointer;
}
`;

export default HeaderStyled;